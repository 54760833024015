<template>
    <div class="fill-height login-wrapper" data-id="login-screen">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" md="8" xl="6">
                    <v-card elevation="24">
                        <v-row class="ma-0">
                            <v-col v-if="!isSmallDevice" md="6" class="img_col">
                                <img
                                    class="logo"
                                    src="https://storage.googleapis.com/dexxter-production-static-files/illustrations/analytics.png"
                                />
                            </v-col>
                            <v-col cols="12" md="6" class="px-4 py-4 px-sm-12 py-sm-8 text-center">
                                <div class="banner-dexxter mb-6">
                                    <img alt="Dexxter" src="@/assets/logo.svg" />
                                </div>

                                <slot />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { screenSizeMixin } from '../mixins/screenSizeMixin';
export default {
    mixins: [screenSizeMixin]
};
</script>

<style lang="scss" scoped>
.img_col {
    border-top-left-radius: $border-radius-root;
    border-bottom-left-radius: $border-radius-root;
    background-color: rgba(232, 229, 241, 0.48);
    display: flex;
    align-items: center;

    img {
        width: 100%;
    }
}

.banner-dexxter {
    text-align: center;
    img {
        width: 60%;
    }
}
</style>

<template>
    <login-layout>
        <!-- Tabindex for keyup focus event: https://stackoverflow.com/questions/6633248/how-to-grab-keyboard-events-on-an-element-which-doesnt-accept-focus -->
        <div tabindex="0" @keyup.enter="loginUser">
            <v-form id="loginForm" v-model="valid">
                <v-col cols="12 px-0">
                    <text-field
                        id="email"
                        v-model="email"
                        tabindex="1"
                        autofocus
                        :label="$t('general.email')"
                        name="login"
                        prepend-inner-icon="mdi-account"
                        type="email"
                        outlined
                        dense
                        persistent-hint
                        placeholder=" "
                        persistent-placeholder
                        autocomplete="username"
                        :rules="emailRules"
                    />
                </v-col>
                <v-col cols="12 px-0">
                    <v-text-field
                        id="current-password"
                        v-model="password"
                        tabindex="2"
                        :label="$t('general.password')"
                        name="password"
                        prepend-inner-icon="mdi-lock"
                        type="password"
                        outlined
                        dense
                        placeholder=" "
                        persistent-placeholder
                        autocomplete="current-password"
                        :rules="requiredRules"
                    />
                </v-col>
            </v-form>
            <v-card-actions class="pa-0">
                <v-spacer />
                <v-btn
                    x-small
                    text
                    color="primary"
                    class="font-weight-bold"
                    tabindex="5"
                    @click="$router.push('forgot-password')"
                >
                    {{ $t('login.forgot_password') }}
                </v-btn>
            </v-card-actions>
            <v-card-actions class="mt-8 pa-0">
                <div>
                    <v-btn
                        :small="!$vuetify.breakpoint.smAndUp"
                        :large="$vuetify.breakpoint.smAndUp"
                        tabindex="4"
                        outlined
                        color="primary"
                        class="button-focus-ring secondary-button font-weight-bold px-sm-8"
                        @click="$router.push('register')"
                    >
                        {{ $t('login.registration') }}
                    </v-btn>
                </div>
                <v-spacer />
                <v-btn
                    id="login"
                    data-action="login"
                    tabindex="3"
                    :small="!$vuetify.breakpoint.smAndUp"
                    :large="$vuetify.breakpoint.smAndUp"
                    :disabled="!valid"
                    color="primary"
                    class="button-focus-ring font-weight-bold px-sm-8"
                    @click.stop="loginUser"
                    @keyup.enter.stop
                >
                    {{ $t('general.login') }}
                </v-btn>
            </v-card-actions>
        </div>

        <confirmation-dialog
            v-if="openAcertaResetPasswordDialog"
            v-model="openAcertaResetPasswordDialog"
            title="Nieuwe aanmeldmethode voor Acerta-klanten"
            message="Dexxter-gebruikers die klant zijn bij Acerta, moeten voortaan aanmelden via een apart wachtwoord (en dus niet meer via MyAcerta).<br><br>To do:<ol><li>Kies een (nieuw) wachtwoord door <a href='https://app.dexxter.be/forgot-password' target='_blank'>hier te klikken</a>.</li><li>Check je mail en volg de instructies.</li><li><b>Bij je volgende aanmelding hoef je dus niet meer op Acerta te klikken.</b> Voortaan kan je gewoon aanmelden met je e-mailadres en Dexxter-wachtwoord.</li></ol><br>De reden van deze wijziging is omdat het aanmelden niet voor iedereen duidelijk was.<br>Onze excuses voor het ongemak!"
        />

        <loader v-if="loading" />
    </login-layout>
</template>

<script>
import { resetLoggingState } from '@/helpers/auth';
import { redirectToCorrectStartPage } from '@/helpers/authNavigation';
import { apiErrorAndDisplay, displayError } from '@/helpers/errorHandling';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { analyticsIdentify, analyticsTrack } from '@/services/analytics';
import VueRouter from 'vue-router';
import { mapActions, mapState } from 'vuex';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog.vue';
import LoginLayout from '../../layouts/LoginLayout.vue';
import { removeHTMLTags } from '../../setup/filters';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
    components: { LoginLayout, ConfirmationDialog },
    mixins: [formRulesMixin, screenSizeMixin],
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
            valid: true,
            loading: false,
            openAcertaResetPasswordDialog: false
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData'])
    },
    mounted() {
        const status = removeHTMLTags(this.$route.query.status);
        if (status) {
            displayError.call(this, this.$i18n.t(`errors.${status}`), this.$i18n.t(`errors.${status}`));
        }
    },
    methods: {
        ...mapActions('auth', ['login', 'changeCognitoPassword']),
        clickMoreInfoRemovalAcerta() {
            this.openAcertaResetPasswordDialog = true;
        },
        async loginUser() {
            try {
                this.loading = true;

                if (!(this.email.length > 0) || !(this.password.length > 0)) return;
                const email = this.email.toLowerCase();
                const password = this.password;

                if (email !== 'robbeclaessens@outlook.be' && email !== 'info-admin@dexxter.be') {
                    await this.$router.push({
                        name: 'userMigrated'
                    });
                    return;
                }

                await this.login({ email, password });

                resetLoggingState();

                const [userData] = await Promise.all([
                    this.$store.dispatch('auth/getCurrentUser'),
                    this.$store.dispatch('auth/getPermissions')
                ]);

                analyticsIdentify(userData.id, {
                    email: userData.userinformation.email,
                    firstName: userData.userinformation.firstName,
                    lastName: userData.userinformation.name
                });
                analyticsTrack('Sign In');

                // Fetch current user
                await redirectToCorrectStartPage(userData, this.$route.query.redirect);
            } catch (e) {
                if (isNavigationFailure(e, NavigationFailureType.redirected)) {
                    // Ignore
                    return;
                }

                if (e.code === 'NotAuthorizedException') {
                    displayError.call(this, this.$t('general.error'), this.$t('errors.incorrectPassword'));
                    return;
                }

                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.button-focus-ring {
    transition: outline 0.2s ease-out;
    &:focus {
        outline: 2px solid $color-primary;

        &.secondary-button::before {
            opacity: 0;
        }
    }
}
</style>

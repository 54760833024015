import { UserObjectTypeViewModels } from 'dexxter-types';
import { Route } from 'vue-router';
import router from '@/router/index';
import { isAccountant, isAdmin } from './auth';

export function redirectToCorrectStartPage(user: UserObjectTypeViewModels, redirect?: string): Promise<Route> {
    if (redirect) {
        return router.replace(redirect);
    }
    if (isAccountant(user)) {
        return router.replace({ name: 'accountant' });
    } else if (isAdmin(user)) {
        return router.replace({ name: 'users' });
    } else {
        return router.replace({ name: 'dashboard' });
    }
}
